import React from "react"
import styles from "./hero-pricing-page.module.scss"
import { openOverlay } from "../../../../../utils/overlay"

const HeroPricingPage = ({ name }) => (
  <div className={styles.hero}>

    <div className={`container ${styles.container}`}>
      <h1 className={styles.heading}>
        Выберите тариф,<br className={"hide-mobile"}/> который подойдет вашему бизнесу
      </h1>
      <p className={styles.subheading}>или попробуйте нашу программу в течение 10 дней бесплатно</p>
      <button type='button'
              className={styles.cta}
              onClick={() => openOverlay(name, "hero", "trial")}>Попробовать бесплатно
      </button>
    </div>

  </div>
)

export default HeroPricingPage
