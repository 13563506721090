import React, { useState } from "react"
import styles from "./accordion.module.scss"
import AnimateHeight from "react-animate-height"

const data = [
  {
    name: "trial",
    title: "Как работает тестовый период?",
    content: `Мы предоставляем бесплатный тестовый период на 10 дней. У вас будет возможность использовать полную функциональность системы и полноценно работать с клиентской базой. Если в конце тестового периода вы решите продолжить сотрудничество, все внесенные данные будут сохранены в системе.`,
  },
  {
    name: "getting-started",
    title: "Как начать работать с Параплан?",
    content: `Система Параплан на 100% работает через интернет. Вам НЕ нужно скачивать или устанавливать файлы на компьютер. Все, что нужно - это открыть ссылку в браузере на любом компьютере, планшете или телефоне и начать работу в системе.`,
  },
  {
    name: "payment-types",
    title: "Какие способы оплаты у вас есть?",
    content: `Вы можете выбрать для оплаты любого тарифа один из способов:

    I. ОПЛАТА БАНКОВСКОЙ КАРТОЙ.

    Предлагаем 2 способа оплаты

    В СИСТЕМЕ
    1.ЗАЙДИТЕ В СВОЙ АККАУНТ В ПАРАПЛАН
    2.ПЕРЕЙДИТЕ В РАЗДЕЛ “НАСТРОЙКИ”, ДАЛЕЕ ВКЛАДКА “ПОЛЬЗОВАТЕЛЬ”
    3.ВВЕДИТЕ СУММУ К ОПЛАТЕ В ПУСТОМ ПОЛЕ РАЗДЕЛА “ОПЛАТА ПОДПИСКИ”
    4.НАЖМИТЕ НА КНОПКУ “ПЕРЕЙТИ К ОПЛАТЕ”
    5.ВВЕДИТЕ РЕКВИЗИТЫ
    номер карты, имя владельца, срок действия карты и код CVV/CVC (3 цифры на обороте)
    6.НАЖМИТЕ КНОПКУ “ОПЛАТИТЬ”
    7.ОБЯЗАТЕЛЬНО
    сообщите специалисту Службы заботы о факте оплаты в чат, чтобы он открыл или продлил вам доступ
    
    ПО ССЫЛКЕ
    1. Перейдите по ссылке, которую Вам направил специалист Службы заботы.
    2. Введите реквизиты Вашей банковской карты: номер карты, имя владельца, срок действия карты и код cvv/cvc (3 цифры на обороте). 
    ВАЖНО! Ссылка одноразовая, вернуться и попробовать оплатить с другой карты нельзя!
    3. Нажмите кнопку «Оплатить».
    4. ОБЯЗАТЕЛЬНО сообщите специалисту Службы заботы о факте оплаты в чат, чтобы он открыл или продлил Вам доступ.

    II. ОПЛАТА ЧЕРЕЗ РАСЧЕТНЫЙ СЧЕТ.
    1. Напишите специалисту Службы заботы адрес электронной почты и реквизиты Вашей компании для отправки счета.
    2. После получения счета на указанную почту оплатите его в стандартном порядке.
    3. ОБЯЗАТЕЛЬНО сообщите специалисту Службы заботы о произведенной оплате и пришлите скан платежного поручения в чат, чтобы сотрудник открыл или продлил Вам доступ.

    III. ОПЛАТА ЧЕРЕЗ САЙТ.
    1. Выберите подходящий тариф на этой странице сайта.
    2. Нажмите «Купить».
    3. Введите Ваш клиентский номер.
    Клиентский номер Вы можете узнать в самой системе в разделе “Настройки”, далее “Пользователь”.
    4. Перейдите к оплате.
    5. Введите реквизиты Вашей банковской карты: номер карты, имя владельца, срок действия карты и код CVV/CVC (3 цифры на обороте), адрес электронной почты или номер телефона для получения чека.
    6. Нажмите «Оплатить».
    7. ОБЯЗАТЕЛЬНО сообщите специалисту Службы заботы о факте оплаты в чат, чтобы он открыл или продлил Вам доступ.`,
  },
  {
    name: "close-docs",
    title: "Какие закрывающие документы вы предоставляете?",
    content: `В зависимости от способа оплаты мы предоставляем определенные бухгалтерские закрывающие документы.\n
    I. ВЫ ОПЛАТИЛИ ПО КАРТЕ.\n\n Закрывающий документ - только электронный кассовый чек.\n Важно! Карта должна быть одной из основных платежных систем: VISA, Mastercard, МИР.\n\n
    II. ВЫ ОПЛАТИЛИ ПО БИЗНЕС-КАРТЕ.\n\n Закрывающий документ - только электронный кассовый чек.\n Так как оплата по бизнес-карте считается онлайн-оплатой.\n\n
    III. ВЫ ОПЛАТИЛИ НА РАСЧЕТНЫЙ СЧЕТ.\n\n Закрывающий документ - акт выполненных работ или электронный кассовый чек \n\n Оригиналы закрывающих документов мы можем отправить по почте России или обменяться ими в системе электронного документооборота СБИС или Диадок. \n\n Выберите удобный вариант работы и запросите документы у Службы Заботы Параплана.
    `,
  },
  {
    name: "choosing-plan",
    title: "Какой тариф мне подойдет?",
    content: ` 
    - "Мини". Экономный тариф для вашего бизнеса. Подходит для небольших центров с клиентской базой до 50 учеников.
    - "Опти". Удобный тариф для знакомства с CRM-системой. Количество учеников, групп, пользователей неограниченно.
    - "Макси". Тариф без границ: все возможности Параплан для вашего центра. Включает все функциональности тарифа Опти. Идеально подойдет для полноценной работы с системой.
    - "Франшизы". Полная функциональность Параплан CRM для сетей и филиалов. Более гибкая система расчета стоимости подписки. Личные кабинеты учеников, IP-телефония, онлайн-расписание, интеграции.

    Если сомневаетесь, какой тариф выбрать, обратитесь к менеджеру Параплан. Будем рады помочь!`,
  },
  {
    name: "monthly-payment",
    title: "Могу ли я платить помесячно?",
    content: `Без проблем. Мы предлагаем тарифы на 1, 3, 6 и 12 месяцев. Большинство наших пользователей делают выбор в пользу тарифов от 3-х до 12-ти месяцев, так как они выгоднее на 5-15%. Однако вариант ежемесячной оплаты также доступен.`,
  },
  {
    name: "additional-integrations",
    title: "Есть ли дополнительные интеграции?",
    content: `Да, мы предлагаем дополнительные интеграции:
    
    - WhatsApp, Telegram для переписок сразу в системе.
    - Рассылки и автоуведомления в WhatsApp
    - Личные кабинеты клиентов для онлайн оплаты и контроля посещений.
    - IР-телефония для работы со звонками.
    - Онлайн-касса для автоматической отправки данных в налоговую.
    - Виджет расписания и сбора заявок для соц. сетей, сайта.
    - Viber с доступом из системы для общения и звонков.
    - СМС-рассылки для напоминания о себе.
    - Автоуведомления для клиентов и сотрудников.
    
    Стоимость интеграций в Параплан CRM:
    
    - Для тарифа “Мини” все интеграции недоступны.
    - Для тарифа “Опти” доступны только "СМС-рассылки", WhatsApp, Telegram для переписок в системе.
    - Для тарифов “Макси” и “Франшизы” все интеграции доступны для настройки и подключения.
    
    При возникновении вопросов свяжитесь со Службой заботы Параплан.`,
  },
]


const AccordionComponent = () => {
  const [tab, setTab] = useState(null)
  return <div className={`container ${styles.tabs}`}>
    <h2 className={styles.heading}>FAQs</h2>
    {data.map(t =>
      <div key={t.name}
           className={`${styles.tab} ${t.name === (tab && tab.name) ? styles.open : ""}`}
           onClick={() => setTab(t !== tab ? t : null)}>
        <div className={styles.info}>
          <div className={styles.title}>{t.title}</div>
          <AnimateHeight duration={300} height={t.name === (tab && tab.name) ? "auto" : 0}>
            <div className={styles.content}>{t.content}</div>
          </AnimateHeight>
        </div>
        <div className={styles.chevron}>
          <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.05287 11.2318L0.435026 2.66346C0.367169 1.27492 1.01181 0.631447 2.33502 0.733047L9.90108 8.28535L17.4671 0.733047C18.7903 0.631447 19.435 1.27492 19.3671 2.66346L10.7832 11.2318C10.1386 11.8414 9.66358 11.8414 9.05287 11.2318Z"
              fill="currentColor"/>
          </svg>
        </div>
      </div>,
    )}
  </div>
}

export default AccordionComponent
