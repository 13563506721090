import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactFormComponent from "../components/pages/shared/contact-form"
import HeroPricingPage from "../components/pages/shared/hero/pricing-page"
import PricingComponent from "../components/pages/main/detail-pricing"
import AccordionComponent from "../components/pages/pricing/accordion"
import Discount from "../components/pages/main/discount"
import Breadcrumb from '../components/controls/breadcrumbs';

const IndexPage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Тарифы', url: ''}
  ];
    return (
      <Layout page={"pricing"}>
          <SEO
            title="Тарифы"
            description="Гибкие тарифы в Параплан CRM. Без доплат за группы, учеников или сотрудников. Подберите выгодные условия использования Параплан - облачной CRM системы для сетей и франшиз, детских и учебных центров, танцевальных и спортивных школ, языковых центров, детских клубов и фитнес-центров."
            url={"https://paraplancrm.ru" + props.location.pathname}
          />
          <HeroPricingPage name={"pricing"}/>
          <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
          <PricingComponent className={"pt-30"}/>
          <Discount/>
          <AccordionComponent/>
          <ContactFormComponent color={"#F9F9FA"}/>
      </Layout>
    )
}

export default IndexPage
